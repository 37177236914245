import styled from "styled-components"
import { device } from "../../../theme"

export const SectionWrap = styled.section`
  padding-top: 53px;
  padding-bottom: 66px;
  @media ${device.medium} {
    padding-top: 54px;
    padding-bottom: 52px;
  }
  @media ${device.small} {
    padding-top: 39px;
    padding-bottom: 36px;
  }
`
export const PoliticaWrap = styled.li`
  border: 1px solid rgb(75, 115, 178);
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 8px;
  font-weight: 600;
  color: black;
`
