import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Text from "../../../components/ui/text"
import { StaticImage } from "gatsby-plugin-image"
import { SectionWrap, PoliticaWrap } from "./style"

const BoxIconSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col md={6} mb={4}>
            <StaticImage
              src="../../../assets/images/imagen-politicas.jpg"
              alt="imagen politicas"
            />
          </Col>
          <Col md={6}>
            <h5 className="bold text-center mb-4">
              Políticas de la empresa para entregarte soluciones óptimas
            </h5>
            <Text>
              En Mercantil, nuestras políticas garantizan prácticas éticas,
              sostenibilidad y excelencia operativa. La Política Antisoborno
              refuerza la integridad; la Responsabilidad Social Empresarial
              promueve el desarrollo sostenible; y el Sistema Integrado de
              Gestión asegura calidad, cuidado ambiental y seguridad. Así,
              entregamos soluciones óptimas con impacto positivo en la sociedad
              y el entorno.
            </Text>

            <ul className="list-group bold">
              <PoliticaWrap>
                <a href="https://mercantil.cdn.prismic.io/mercantil/Z1xb6ZbqstJ98fFe_PoliticaAntisoborno1.0.pdf">
                  <i className="fas fa-plus mx-3" />
                  Política Antisoborno
                </a>
              </PoliticaWrap>

              <PoliticaWrap>
                <a href="https://mercantil.cdn.prismic.io/mercantil/Z1xcwpbqstJ98fGI_PoliticadeResponsabilidadsocialempresarial1.0.pdf">
                  <i className="fas fa-plus mx-3" />
                  Política de Responsabilidad Social Empresarial
                </a>
              </PoliticaWrap>

              <PoliticaWrap>
                <a href="https://mercantil.cdn.prismic.io/mercantil/Z1xc5ZbqstJ98fGR_PoliticadelSistemaIntegradodeGesti%C3%B3n1.0.pdf">
                  <i className="fas fa-plus mx-3" />
                  Política del Sistema Integrado de Gestión
                </a>
              </PoliticaWrap>
            </ul>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default BoxIconSection
